import UUID from 'uuid';
import { $axios } from './api';

// oss上传文件
const uploadFile = (file: File) => {
  // 获取oss信息
  return new Promise((resolve) => {
    const _oss = localStorage.getItem('oss');
    // 判断签名有没有过期
    const oss = _oss ? JSON.parse(_oss) : null;
    console.log('oss.ts - 11',oss)
    const timestamp = Date.now() / 1000;
    if (oss && oss.hasOwnProperty("dcdnHost") && oss.expire - 3 > timestamp) {
      apiUploadFile(oss, file).then((data) => {
        resolve(data);
      });
    } else {
      getOssInfo().then((res) => {
        if (res) {
          localStorage.setItem('oss', JSON.stringify(res));
          apiUploadFile(res, file).then((data) => {
            resolve(data);
          });
        }
      });
    }
  });
};

// 通过接口上传文件
const apiUploadFile = (data: any, file: File) => {
  const ossData = new FormData();
  // key就代表文件层级和阿里云上的文件名
  const point = file.name.lastIndexOf('.');
  const suffix = file.name.substr(point);
  const keyValue = `${data.dir}/${UUID.v4().replace(/-/g, '')}${suffix}`;

  ossData.append('name', file.name);
  ossData.append('key', keyValue);
  ossData.append('policy', data.policy);
  ossData.append('OSSAccessKeyId', data.accessid);
  ossData.append('success_action_status', '201');
  ossData.append('callback', data.callback);
  ossData.append('signature', data.signature);
  ossData.append('file', file, file.name);

  return new Promise((resolve) => {
    uploadImg(data.host, ossData).then((res: any) => {
      if (res.Status === 'OK') {
        // resolve(`${data.host}/${keyValue}`);
        resolve(`${data.dcdnHost || data.host}/${keyValue}`);
      } else {
        resolve(null);
      }
    });
  });
};

// 获取oss
const getOssInfo = async () => {
  return await $axios.get(`/api/oss/info`);
};

// 上传图片
const uploadImg = async (url: string, params: any) => {
  return await $axios.post(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export { uploadFile };
