import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import _ from 'lodash';
import { $axios, $cookies } from '~/util/api';
import { ResponseCode } from '~/util/constant';

@Module({
  name: 'global',
  stateFactory: true,
  namespaced: true,
})
export default class Global extends VuexModule {
  user = {};

  links = null;

  openId = '';

  isWebp = false; // 是否支持webp

  isLogin = false;

  interfaceResponseTime = '';

  @Mutation
  setUser(data: any) {
    this.user = data;
  }

  @Mutation
  setLinks(data: any) {
    this.links = data;
  }

  @Mutation
  setOpenId(data: string) {
    this.openId = data;
  }

  @Mutation
  setIsWebp(data: boolean) {
    this.isWebp = data;
  }

  @Mutation
  setInterfaceResponseTime(data: string) {
    this.interfaceResponseTime = data;
  }

  @Mutation
  setIsLogin(data: boolean) {
    this.isLogin = data;
  }

  @Action
  async getUser() {
    const userRes: any = await $axios.get('/api/user/getInfo');
    if (userRes.code === ResponseCode.SUCCESS) {
      $cookies.set('uid', userRes.data.id);
      // console.log('userRes >>>', userRes);
      this.setUser(userRes.data);
      this.setOpenId(userRes.data.openId);
    }
  }

  @Action
  async getLinks(action: Record<string, any>) {
    // console.log(111);
    const id = _.get(action, 'id', 0);
    const linksRes: any = await $axios.get(`/api/index/links/${id}`);
    // console.log('71-', linksRes);
    if (linksRes.code === ResponseCode.SUCCESS) {
      const list = _.get(linksRes, 'data.linkList', []);
      this.setLinks(list);
    }
  }
}
