import createPersistedState from 'vuex-persistedstate';
import { Context } from '@nuxt/types';

export default ({ store }: Context) => {
  if (process.client) {
    // if (route.name !== '首页' && route.name !== '展会列表') {
    window.onNuxtReady(() => {
      createPersistedState({
        paths: ['global.user', 'global.openId', 'global.isWebp', 'user', 'order', 'visitor', 'i18n'],
        key: 'store', // 读取本地存储的数据到store
      })(store);
    });
    // }
  }
};
