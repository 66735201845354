import { Store } from 'vuex';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { NuxtCookies } from 'cookie-universal-nuxt';
import VueI18n from "vue-i18n";

// eslint-disable-next-line import/no-mutable-exports
let $axios: NuxtAxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
  $axios = axiosInstance;
}

// eslint-disable-next-line import/no-mutable-exports
let $cookies: NuxtCookies;

export function initializeCookies(cookiesInstance: NuxtCookies) {
  $cookies = cookiesInstance;
}

// eslint-disable-next-line import/no-mutable-exports
let $store: Store<any>;

export function initializeStore(storeInstance: Store<any>) {
  $store = storeInstance;
}

// eslint-disable-next-line import/no-mutable-exports
let $i18n: VueI18n;

export function initializeI18n(instance: VueI18n) {
  $i18n = instance;
}

export { $axios, $cookies, $store, $i18n };
