import * as fundebug from 'fundebug-javascript';
// @ts-ignore
import 'fundebug-revideo'; // fundebug-revideo 0.7.1已更新至0.8.0
// @ts-ignore
import fundebugVue from 'fundebug-vue';
import Vue from 'vue';

// 临时邮箱
// uolnja59421@chacuo.net
fundebug.init({
  apikey: '2f5acb3be02f3ebc9a576021a47a42e195d43a15877191373ed4a25d1667a236',
  releasestage: process.env.NODE_ENV,
  silentResource: true,
  setHttpBody: true,
  filters: [
    {
      target: {
        src: /sfp.safe.baidu.com/,
      },
    },
    {
      target: {
        src: /sofire.baidu.com/,
      },
    },
    {
      target: {
        src: /miao.baidu.com/,
      },
    },
  ],
});

fundebugVue(fundebug, Vue);
