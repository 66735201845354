import _ from 'lodash';
import { Message } from 'element-ui';
import { Context } from '@nuxt/types';
import VueI18n from 'vue-i18n';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
// import { showLogin } from '~/components/Login/index';
import Login from '~/components/Login/index';

import {
  $cookies,
  initializeAxios,
  initializeCookies,
  initializeStore,
  initializeI18n,
} from '~/util/api';
import { ResponseCode } from '~/util/constant';
import { webpIsSupported } from '~/util/common';

enum ELocale {
  zh = 'CN', // 中文
  cn = 'CN', // 中文
  en = 'EN', // 英文
}
// console.log("axios-15",process,process.mode)
// 创建一个axios实例
const axiosInstance = axios.create();

export default async function (ctx: Context) {
  const $axios: any = ctx.app.$axios;
  // $axios.defaults.baseURL = process.env.BASE_URL;
  $axios.defaults.withCredentials = false; // 默认不携带cookie
  $axios.defaults.timeout = 3 * 1000; // 超时 30 * 1000
  // 请求失败重新发起请求
  $axios.defaults.retry = 1; // 最大重发请求次数
  $axios.defaults.retryCount = 0; // 已重发请求次数
  $axios.defaults.retryDelay = 500;
  // 允许状态码的响应 304(资讯详情页oss获取内容时会返回304状态码)
  $axios.defaults.validateStatus = function validateStatus(status: any) {
    return (status >= 200 && status < 300) || status === 304;
  };

  initializeAxios($axios);
  initializeCookies(ctx.app.$cookies);
  initializeStore(ctx.store);
  initializeI18n(<VueI18n>ctx.app.i18n);
  const isWebp = await webpIsSupported();
  ctx.store.commit('global/setIsWebp', isWebp);

  $axios.onRequest((config: AxiosRequestConfig & { meta: any }) => {
    const token: string = ctx.app.$cookies.get('access_token');
    console.log('axios-36-request', config);
    // 打印日志
    // if (ctx.$winstonLog) {
    //   ctx.$winstonLog.info('54-54nuxt服务端日志');
    //   ctx.$winstonLog.info('55-55接口请求', config);
    // }
    // let configRes:any = config
    // console.log(`server: ${process.server};`, 'token >>>', token);
    config.headers['If-Modified-Since'] = ''; // 删除If-Modified-Since头
    // 请求开始时间
    let startTime = new Date().getTime();
    config['meta'] = {
      startTime: startTime,
      pageUrl: config.headers.common?.referer || '',
      params: config.data,
      // ...config?.meta
    };

    config.headers['common']['nuxtTraceId'] = startTime;
    config.headers['common']['referer'] = 'https://www.jufair.com';
    // console.log('66-68', config);
    if (token && config.headers.common) {
      config.headers.common.Authorization = token;
    }
    if (config.url?.startsWith('/ossApi')) {
      // config.headers.Referer = 'jufair.com';
    }
    if (!config.headers.common) {
      config.headers.common = {};
    }
    const locale = <keyof typeof ELocale>(
      _.get(ctx.store.state, 'i18n.locale', 'zh')
    );
    console.log('84-84', locale);
    config.headers.locale = ELocale[locale];
    config.headers.common.DEVICE = 'pc'; // pc h5 weixin baidu api
    console.log('>>>>>', config.url, locale);
    return config;
  });

  // $axios.onResponse((response: AxiosResponse<any>): [] => {
  $axios.onResponse((response: AxiosResponse<any>): any => {
    const { config, status }: any = response;
    // console.log('axios-67-response', response);
    console.log('axios-68-response', response.config.url); // 接口url
    // if (config.url.includes('/ossApi/')) {
    //   console.log('axios-97-response', response);
    // }
    const code = _.get(response, 'data.code', '1');
    const url = _.get(response, 'config.url', '');

    // 请求响应后计算时间
    const endTime = new Date().getTime();
    const duration = endTime - config?.meta?.startTime;
    let pageUrl = config.meta.pageUrl || '';
    let hostName = config.baseURL;
    let interfaceUrl = response.config.url;
    let params = config.meta.params;
    // console.log(`页面:${pageUrl}  接口:${hostName} ${interfaceUrl}  耗时:${duration}毫秒`)
    // console.log(`参数:${params}`)

    let obj = {
      nuxtTraceId: config?.meta?.startTime,
      pageUrl: pageUrl,
      interfaceUrl: interfaceUrl,
      params: params,
      startTime: config.meta.startTime,
      endTime: endTime,
      duration: duration,
    };
    let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
    obj['nuxtTraceId'] = interfaceResponseTime;
    // let timeList = []
    // if (interfaceResponseTime) timeList = JSON.parse(interfaceResponseTime)
    // timeList.push(obj)
    // console.log('85-85',timeList)
    if (!interfaceResponseTime)
      ctx.store.commit('global/setInterfaceResponseTime', obj.startTime);
    // 打印日志
    if (ctx.$winstonLog) {
      ctx.$winstonLog.info('nuxt服务端日志');
      ctx.$winstonLog.info(obj);
    }

    // ctx.store.commit('global/setInterfaceResponseTime', JSON.stringify(obj));
    // let nuxtServerInterfaceTime = sessionStorage.getItem("nuxtServerInterfaceTime");
    // console.log('78-78-78',nuxtServerInterfaceTime)
    // sessionStorage.setItem("nuxtServerInterfaceTime", value);

    const urlPrefixes = ['/api/newsApi/get', '/api/exhibition/'];
    if (urlPrefixes.some((prefix) => config.url?.startsWith(prefix))) {
      return {
        ...response.data,
        informationSecret: response.headers['jufair-inforationsecret'],
      };
    }

    if (config.url?.startsWith('/ossApi')) {
      return response.data;
    }
    if (code === ResponseCode.BLACK_LOGIN) {
      console.trace('先登录', url);
      return response.data;
    }
    // 16002信息不完善,
    console.log('axios-152', code);
    // 未登录清空登录态
    if (
      code === ResponseCode.AUTH_FAILED ||
      code === ResponseCode.NO_LOGIN ||
      code === ResponseCode.RESTART_LOGIN
    ) {
      $cookies.remove('access_token');
      $cookies.remove('phoneNumber');

      Login.show();

      return;
    }

    if (code !== '1') {
      // 微信二维码轮询, 不需要提示.
      if (
        /\/auth\/wxlogin\/.+/.test(url) ||
        /^\/v1/.test(url) ||
        url.includes('ticketOrderApi/getPayStatus') ||
        url.includes('visaOrderApi/payStatus') ||
        url.includes('flightOrderApi/payStatus')
      ) {
        return response.data;
      }
      const msg =
        response.data?.errorMessage || response.data?.message || '请求失败';
      Message({
        message: msg,
        type: 'error',
      });
      console.log(101, config);
      console.error('175-api >>>', config.method, url, status);
      console.error('data >>>', config.data);
      console.error('response.data >>>', response.data);

      if (process.client) {
        console.log('fundebug监控');
        const fundebug = require('fundebug-javascript');
        fundebug.notify('RequestError', response.data?.errorMessage, {
          metaData: {
            response,
            config,
          },
        });
      }
      if (code == 500) {
        return { code: 500, message: '系统异常' };
      } else {
        return response.data;
      }
    }
    return response.data;
  });

  $axios.onError((error: AxiosError<any>) => {
    const { status, config } = (error.response as any) || {};
    console.error('axios-error-onError >>>', error);
    console.error('axios-error-api >>>', config?.method, config?.url, status);
    console.error('axios-error-data >>>', config?.data);
    console.trace('axios-error-error >>>', error, JSON.stringify(config));
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notifyError(error);
    }
    // console.log(110, error.config, JSON.stringify(error.config))
    // 接口请求失败重新发起请求
    // if (config.retry && config.retryCount < config.retry) {
    //   console.log("axios重新发送请求")
    //   // 延迟一段时间后重发请求
    //   const backoff = new Promise(resolve => {
    //     setTimeout(() => resolve(""), config.retryDelay || 1);
    //   });
    //   config.retryCount++
    //   // 返回重发的Promise
    //   return backoff.then(() => axiosInstance(config))
    // }
    // if (config.retry && config.retryCount >= config.retry) {
    //   console.log("axios-129-重新请求结束")
    //   return Promise.resolve({
    //     code: '0',
    //     message: 'API Error',
    //     data: null,
    //   });
    // }
    // 未登录清空登录态
    if ('' + status === ResponseCode.AUTH_FAILED) {
      $cookies.remove('access_token');
      $cookies.remove('phoneNumber');
    }
    return Promise.resolve({
      code: '0',
      message: 'API Error',
      data: null,
    });
  });
}
