import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'visitor',
  stateFactory: true,
  namespaced: true,
})
export default class Visitor extends VuexModule {
  visitorDetail = {};

  @Mutation
  setVisitorDetail(data: Record<string, any>) {
    console.log(this);
    this.visitorDetail = data;
  }
}
