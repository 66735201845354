import { Context } from '@nuxt/types/app';
// import Vue from "vue";
import { $cookies } from '~/util/api';

export default (ctx: Context) => {
  const { app } = ctx;
  (app.router as any).beforeEach((to: any, _from: any, next: any) => {
    // 路由拦截
    const token = $cookies.get('access_token');
    ctx.store.commit('global/setIsLogin', !!token);
    const { isLogin, user } = ctx.store.state.global;
    if (process.client && isLogin) {
      if (!user.isPererffect) {
        // Vue.prototype.$completeInfo.show()
      }
    }
    // 判断该路由是否需要登录权限
    if (to.meta.isLogin) {
      if (!token) {
        // 未登录,跳转到登陆页面
        next({
          path: `/login/?redirect=${encodeURIComponent(to.fullPath)}`,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  app.router?.onError((error) => {
    try {
      const pattern = /Loading chunk (\d)+ failed/g;
      const isChunkLoadFailed = error.message.match(pattern);
      const targetPath = (app.router as any).history.pending.fullPath;
      if (isChunkLoadFailed) {
        app.router?.replace(targetPath);
      }
    } catch (error) {
      // if (process.client) {
      //   const fundebug = require('fundebug-javascript');
      //   fundebug.notifyError(error);
      // }
      console.error(error);
    }
  });
};
